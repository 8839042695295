<template>
  <div class="show-script">
    <div class="script-thumb">
      <el-breadcrumb
        separator-class="el-icon-arrow-right"
        style="margin-left: 11px"
      >
        <el-breadcrumb-item :to="{ path: '/liveStreaming/practicalTrain' }"
          >直播实训题管理</el-breadcrumb-item
        >
        <el-breadcrumb-item>脚本管理</el-breadcrumb-item>
        <el-breadcrumb-item>创建脚本</el-breadcrumb-item>
        <el-breadcrumb-item>预览数据</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="script-window">
      <div class="script-title">
        <span>脚本数据预览</span>
      </div>
      
      <div class="script-main">
        <div class="script-body-item" style="font-size: 20px;font-weight: bold;margin:0 0 20px 117px">直播时长:分钟</div>
        <div
          class="no-data-icon"
        >
          <img
            src="../../../assets/image/no_data.png"
            alt="暂无数据"
            style="margin-bottom: 10px; width: 500px; height: 500px"
          />
          <span class="no-data-font">暂无数据</span>
        </div>
        
        <!-- 开场暖场 -->
        <div class="script-body-item" >
          <!-- 开场暖场标题 -->
          <div class="common-title">
            <div class="common-tag">01</div>
            <div class="common-item">开场暖场</div>
          </div>
          <!-- 主体 -->
          <div class="common-main" >
            <div class="common-main-box">
              <div  style="margin-top: 57px;margin-left:74px;font-weight:bold;width:33px"></div>
              <div class="common-timeline"></div>
              <div class="common-content">
                <div class="content-one">
                  
                  <div class="content-two">
                    <span>关键词：</span>
                    <span style="margin-left: 50px"
                      >错误关键词：</span
                    >
                    <span style="margin-left: 50px">流失率：%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 商品 -->
        <div class="script-body-item" >
          <!-- 开场暖场标题 -->
          <div class="common-title">
            <div class="common-tag">02</div>
            <div class="common-item">商品(选品个)</div>
          </div>
          <div class="common-main" >
            <div class="common-main-box">
              <div  style="margin-top: 57px;margin-left:17px;font-weight:bold;width:90px"></div>
              <div class="common-timeline"></div>
              <div class="common-content">
                <div class="content-one">
                  <span
                    style="color: #3b76fb; font-weight: bold; font-size: 16px"
                    ></span
                  >
                  <div class="content-two">
                    <span>点击率：% </span>
                    <span style="margin-left:50px">转化率：% </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 福袋 -->
        <div class="script-body-item" >
          <!-- 开场暖场标题 -->
          <div class="common-title">
            <div class="common-tag">03</div>
            <div class="common-item">福袋</div>
          </div>
          <div class="common-main" >
            <div class="common-main-box">
              <div  style="margin-top: 57px;margin-left:17px;font-weight:bold;width:90px"></div>
              <div class="common-timeline"></div>
              <div class="common-content">
                <div class="content-one">
                  <span style="font-size: 16px; font-weight: bold"
                    ></span
                  >

                  <div class="content-two">
                    <span>倒计时：</span>
                    <span style="margin-left: 50px">参与方式：</span>
                    <span style="margin-left: 50px"
                      >参与口令：</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 结束 -->
        <div class="script-body-item">
          <!-- 结束语 -->
          <div class="common-title">
            <div class="common-tag">04</div>
            <div class="common-item">结束语</div>
          </div>
          <div class="common-main" >
            <div class="common-main-box">
              <div  style="margin-top: 57px;margin-left:74px;font-weight:bold;width:33px"></div>
              <div class="common-timeline"></div>
              <div class="common-content">
                <div class="content-one">
                  
                  <div class="content-two">
                    <span>关键词：</span>
                    <span style="margin-left: 50px"
                      >错误关键词：</span
                    >
                    <span style="margin-left: 50px">流失率：%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      showData:'',
    };
  },
  mounted() {
    // if (localStorage.getItem('liveShowData')) {
    //   this.showData=JSON.parse(localStorage.getItem('liveShowData'))
    // } else {
    //   this.$message({
    //     type: "warning",
    //     message: "暂无预览数据",
    //   });
    // }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none; 
}
.show-script {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  .script-thumb {
    padding: 30px 0 29px 33px;
  }
  .script-window {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4088px;
    width: 100%;
    background: url("../../../assets/image/script_background.png");
    background-repeat: no-repeat;
    background-size:100% 100%;
    position: relative;
    .script-title {
      position: absolute;
      top: 1.4%;
      left: 50%;
      margin-left: -10%;
      width: 100%;
      height: 100px;
      background: url("../../../assets/image/script_title.png");
      background-repeat: no-repeat;
      span {
        position: absolute;
        left: 4.6%;
        top: 25%;
        font-size: 36px;
        font-weight: bold;
        color: #ffffff;
      }
    }
    .script-main {
      margin: 116px 98px 100px 98px;
      background: #ffffff;
      border-radius: 20px;
      height: 95%;
      width: 100%;
      .no-data-icon {
        margin-top: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .no-data-font {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 26px;
          font-weight: bold;
          color: #a8a8b8;
          margin-bottom: 30px;
        }
      }
      .script-body-item {
        width: 100%;
        position: relative;
        top: 3%;
        .common-title {
          margin: 10px 0 0 114px;
          display: flex;
          background: #e8edf9;
          width: 202px;
          height: 37px;
          border-radius: 13px;
          font-size: 18px;
          font-weight: bold;
          text-align: center;
          line-height: 37px;
          .common-tag {
            width: 37px;
            height: 37px;
            background: #2821fc;
            border-radius: 13px 13px 0px 13px;
            color: #ffffff;
          }
          .common-item {
            margin-left: 19px;
          }
        }
        .common-main {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          width: 100%;
          .common-main-box {
            display: flex;
            .common-time {
              font-weight: bold;
              padding-left: 5%;
            }
            .common-timeline {
              margin-left: 1.5%;
              border-right: 2px dashed #cad4fd;
              height: auto;
            }
            .common-content {
              margin: 15px 0 0 30px;
              width: 85%;
              height: auto;
              background: #f8f8f8;
              .content-one {
                padding: 18px 17px 20px 21px;
              }
              .content-two {
                margin-top: 20px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
